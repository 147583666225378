import React, { Component } from 'react'

export default class AboutMidImage extends Component {
  render() {
      if(window.innerWidth > 668){
          return(
            <img src='images/imageswindow.png' className='about-head-img'/>
          )
      }else{
        return(
            <img src='images/imagesmobile.png' className='about-head-img'/>
          )
      }
  }
}
