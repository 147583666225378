import React, { Component,useState } from 'react';
import { Link, Navbar } from 'react-router-dom';
import Slider from "react-slick";
import Contactform from "./FormsComponent/ContactForm";
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default function Contact() {
//     const [status, setStatus] = useState("Submit");
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");
//     const { name, email,lname,phone, message } = e.target.elements;
//     let details = {
//       name: name.value,
//       lname: lname.value,
//       email: email.value,
//       phone: phone.value,
//       message: message.value,
//     };
//     let response = await fetch("https://test.revooerp.com/contact", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(details),
//     });
//     setStatus("Submit Done");
//     let result = await response.json();
//     alert(result.status);
//     console.log(response);
//   };
// console.log(window.location.href);
    return (
        <>
        <Nav />
            <div className='testinomial-section'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <div class="map-canvas">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.4903983109452!2d72.97836401532288!3d19.217447887006838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b95eef9e178d%3A0xccec2d6a6fd7e98d!2sHigh%20Street%20Mall!5e0!3m2!1sen!2sin!4v1641631706005!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='team-role'>Location</div>
                        <div className='about-team'>
                            <p>4B-20A, High Street Mall, Kapurbawdi Junction, Thane(W) 400607, India.
                            </p>
                        </div>

                        <div className='team-role'>Email</div>
                        <div className='about-team'>
                            <p>revooerp@gmail.com
                            </p>
                        </div>

                        <div className='team-role'>Phone</div>
                        <div className='about-team'>
                            <p>+91 9967706767 <br /> +91 2246034552
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
    <section className='trail'>
        <Contactform/>
    </section>

    <Footer />
        </>
    )
}