import React, { useState } from 'react'
import Appslider from './AppSlider'
import Testimonials from './Testimonials'
import Iframe from 'react-iframe'
import Popup from './Popup'
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  // console.log(window.location.href);
  return (
      <>
      <Nav />
      <source src="video/revoo.mp4" type="video/mp4"/>
        <section className='first-section pb-50'>
          <div className='container-fluid'>
            <div className='row'>
            <div className='col-md-4'>
            <div className='col-md-4 order-md-2'>
                <img className='one-img' height={1200} width={1200} src='images/ellipse.png'/>
                  {/* <a className='play-button' onClick={togglePopup}><i className="fa fa-play" aria-hidden="true"></i></a> */}
                  {/* <a className='play-button' onClick={togglePopup}><img className='circle-image' src='images/revoo.gif'/></a> */}
                  {/* {isOpen && <Popup
                    content={<>
                      <Iframe url="https://revooerp.com/revoo.mp4" width="620px" height="350px"/>
                    </>}
                    handleClose={togglePopup}
                  />} */}
              </div>
                <div className='section-one-home'>
                  <div className='title-one'>
                    <h1>Many Needs? One Solution- Revoo!</h1>
                  </div>
                  <p>Revoo, your personal guide for entrepreneurial success. Manage, Access, Maintain and Grow your business with Revoo, The one-stop-shop solution for every business need.</p>
                  <div className='first-section-button'>
                    <button className='btn home-section'>Download Now!</button>
                  </div>
                </div>
              </div>
              <div className='col-md-8 order-md-6'>
                <img className='img-video' src='images/many.gif'/>
              </div>
              
              
              
            </div>
          </div>
        </section>
        <section className='second-section bg-img-second'>
          <div className='container content-second'>
            <div className='section-title'><h1>Why Revoo?</h1></div>
            <div className=''>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='why-content'>
                    <div className='disp-whyrevoo'>
                      <div className='disc-image'>
                        <img src='images/1.png'/>
                      </div>
                    </div>
                    <div className='content-two'>
                      <h1>Transparent Management</h1>
                      <p>A single source of access one integrated database for all business processes</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='why-content'>
                    <div className='disp-whyrevoo'>
                      <div className='disc-image'>
                        <img src='images/2.png'/>
                      </div>
                    </div>
                    <div className='content-two'>
                      <h1>Precise Planning</h1>
                      <p>With information readily available, focus on better planning & management.</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='why-content'>
                    <div className='disp-whyrevoo'>
                      <div className='disc-image'>
                        <img src='images/3.png'/>
                      </div>
                    </div>
                    <div className='content-two'>
                      <h1>Flexibility</h1>
                      <p>Adjusting the scope & scale of business  according to current needs and conditions.</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='why-content'>
                    <div className='disp-whyrevoo'>
                      <div className='disc-image'>
                        <img src='images/4.png'/>
                      </div>
                    </div>
                    <div className='content-two'>
                      <h1>Cost Cutting</h1>
                      <p>All systems integrated greatly help in reduced business costs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='third-section bg-img-third'>
            <div className='section-title'><h1>Our Apps at your service!</h1></div>
            <div className='app-slider'>
              <Appslider/>
            </div>
        </section>
        <section className='fourth-section bg-img-fourth'>
          <div className='container'>
            <div className='correct-fourth'>
              <div className='roadmap'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='roadmap-title'>
                      <h1>Choose Your Steps Towards Success</h1>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                      <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top"  data-content="And here's some amazing content. It's very engaging. Right?" data-original-title={2003}>
                          <div className="inner-circle" />
                          <p className="h6 mt-3 mb-1">Step 1</p>
                          <p className="h6 text-muted mb-0 mb-lg-0">Sign Up</p>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top"  data-content="And here's some amazing content. It's very engaging. Right?" data-original-title={2004}>
                          <div className="inner-circle" />
                          <p className="h6 mt-3 mb-1">Step 2</p>
                          <p className="h6 text-muted mb-0 mb-lg-0">Choose Product</p>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top"  data-content="And here's some amazing content. It's very engaging. Right?" data-original-title={2005}>
                          <div className="inner-circle" />
                          <p className="h6 mt-3 mb-1">Step 3</p>
                          <p className="h6 text-muted mb-0 mb-lg-0">Road To Success</p>
                        </div>
                      </div>
                    </div>
                    <div className='get-started'><button className='btn'>Get Started</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className='container '>
            <div className='correct-fourth'>
              <div className='row'>
                <div className='col-md-5'>
                  <img className='image-flex' src='images/running-graph.png'/>
                </div>
                <div className='col-md-7'>
                  <div className='subtitle-1'>
                    <h1>Constant Growth</h1>
                    <p>As an Entrepreneur, Growth is the only constant on your mind. Make Growth a new normal in your business module with Revoo! With our integrated open source app model based ERP Revoo! You get all things in one place. Without having to ponder over your internal management, you will get enormous growth opportunities with Revoo.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='mid-line'>
          <img src="images/blueline.png"></img>
          <img className='two-img-midline' src="images/greyline.png"></img>
        </section>
        <section className='fifth'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='container'>
                  <div className='section-2'>
                    <h1>Multiverse of Apps</h1>
                    <p>With the Multiversal app approach being the new trendy thing in the working environment, why be outdated? Get Revoo now and open your own multiversal app in just one place. Get all your management needs in one place and boost your working environment.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                <div className='section-2-img'>
                  <img className='image-flex' src='images/section2.png'></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='testinomial'>
          <div className='container'>
            <Testimonials/>
          </div>
        </section>
        <section className='mission-vision'>
          <div className='container'>
            <div className='mission'>
              <div className='row'>
                <div className='col-md-4'>
                  <h1>Our mission</h1>
                </div>
                <div className='col-md-8'>
                  <p>We have pledged to increase the flexibility and reliability of the business modules. We look to diversify the business approach of many entrepreneurs by providing them with the best ERP software- Revoo! We look to make the multiverse of apps a new normal in the business environment, which will play a game-changing knock in the marketing near future.</p>
                </div>
              </div>
            </div>
            <div className='vision'>
              <div className='row'>
                <div className='col-md-4'>
                  <h1>Our vision</h1>
                </div>
                <div className='col-md-8'>
                  <p>Extend our support and services to every business with a view to increase its targeted Growth and reach. Our grand vision is to make Revoo a common name in the business environment, without which it is almost impossible to play the big game. We will make your business stand apart from everyone with our best multiversal management ERP software- Revoo!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
  )
}