import React, { Component } from "react";
import Slider from "react-slick";

export default class Appslider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows:true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      // prevArrow:'<button type="button" data-role="none" class="slick-prev">Previous</button>',
      // nextArrow:'<button type="button" data-role="none" class="slick-next">Next</button>',
      responsive: [
        {
          breakpoint:1500,
          settings:{
            slidesToShow:2,
            slidesToScroll:1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1008,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode:true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode:true,
          },
          // settings: 'unslick',
        },
        {
          breakpoint: 608,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1><br/>Administration</h1></div>
                <div className="card-p"><p>Manage <br/>the Management.</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/administration/department.png"/>
                  <img className="app-img" src="images/administration/video-camera.png"/>
                  <img className="app-img" src="images/administration/file.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Accounting and <br/>Financials</h1></div>
                <div className="card-p"><p>Add fun and Minus <br/>the burden.</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/accounting/acc_1.png"/>
                  <img className="app-img" src="images/accounting/acc_2.png"/>
                  <img className="app-img" src="images/accounting/acc_3.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Purchasing  and <br/>Operations</h1></div>
                <div className="card-p"><p>Quality operations with a <br/>positive attitude.</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/purchase/pur_1.png"/>
                  <img className="app-img" src="images/purchase/pur_2.png"/>
                  <img className="app-img" src="images/purchase/pur_3.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Sales  and <br/>Services</h1></div>
                <div className="card-p"><p>Make sales a habit <br/>Not work!</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/sales/sls_1.png"/>
                  <img className="app-img" src="images/sales/sls_2.png"/>
                  <img className="app-img" src="images/sales/sls_3.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          {/* <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Production and <br/>MRP</h1></div>
                <div className="card-p"><p>Produce more and <br/>more efficiently.</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/rectangle.png"/>
                  <img className="app-img" src="images/rectangle.png"/>
                  <img className="app-img" src="images/rectangle.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div> */}

          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Project and <br/>Resources Management</h1></div>
                <div className="card-p"><p>Manage your project <br/>& resources effectively!</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/mangement/mgt_1.png"/>
                  <img className="app-img" src="images/mangement/mgt_2.png"/>
                  <img className="app-img" src="images/mangement/mgt_3.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Inventory <br/>Management</h1></div>
                <div className="card-p"><p>Be up-to-date with your <br/>inventory.</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/inventory/inv_1.png"/>
                  <img className="app-img" src="images/inventory/inv_2.png"/>
                  <img className="app-img" src="images/inventory/inv_3.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div>

          {/* <div className="container app-card-pad">
            <div className="card">
                <div className="card-title"><h1>Solution and <br/>Customising</h1></div>
                <div className="card-p"><p>Customise yourself<br/>and your brand!</p></div>
                <div className="app-functions">
                  <img className="app-img" src="images/rectangle.png"/>
                  <img className="app-img" src="images/rectangle.png"/>
                  <img className="app-img" src="images/rectangle.png"/>
                </div>
                <div className="app-button">
                  <button className="btn button-app">See more...</button>
                </div>
            </div>
          </div> */}
        </Slider>
      </div>
    );
  }
}