import React from 'react';
import './adminApp.css';
import Sidebar from './components/Sidebar';

const adminDashboard = () => {
    return (
        <>
            <div className="txt-container">
                <Sidebar />
                <div>
                    <h1>Admin Dashboard page</h1>
                </div>
            </div>
        </>

    );
};

export default adminDashboard;