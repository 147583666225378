import React from 'react'
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default function Products() {
  return (
    <>
    <Nav />
    <section className='product-head'>
      <div className='product-head-img'>
        <img src='images/ourappslider.png' />
        <div className='product-title-slider'>
          <h1>Our Apps <br />at your service!</h1>
          <p>Any tagline like "Choose your plan/<br /> Choose your apps now!"</p>
          <div class="first-section-button"><button class="btn home-section">Download Now!</button></div>
        </div>
      </div>
    </section>
      <section className='our-app'>
        <div className='container'>
          <div className='our-app-title'>
            <h1>Our Apps</h1>
          </div>


          <div className='app-name'>
            <h1>Administration</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Create Branches</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/branch.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Create Departments</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/department.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Shifts Management</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/video-camera.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Report</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/file.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Manage Employees</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/user.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Manage Leave Request</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/user.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Manage Certificates</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/administration/user.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='app-name'>
            <h1>HRMS</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Create Employees</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Create Shift Slot</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="product-card">
              <div className="product-card-title">
                <h1>Location Based Attendance</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Connection With Biometric</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="product-card">
              <div className="product-card-title">
                <h1>Leave Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="product-card">
              <div className="product-card-title">
                <h1>Live Meetings</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_6.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Location Tracking For Staff</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_7.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="product-card">
              <div className="product-card-title">
                <h1>Reports</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_8.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="product-card">
              <div className="product-card-title">
                <h1>HR PayRolls</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_9.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Face Attendance</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_10.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="product-card">
              <div className="product-card-title">
                <h1>Certificate Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_11.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="product-card">
              <div className="product-card-title">
                <h1>Personalized Notifications</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_12.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Birthday Alerts</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_14.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Alert On Excess Holidays</h1>
              </div>
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_15.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="product-card">
              <div className="product-card-title">
                <h1>Project Meetings</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/hrms/emp_16.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='app-name'>
            <h1>Accounting And Finance</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Account And Chart</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/accounting/acc_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Auto Journal Entries</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/accounting/acc_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Template For Payments</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/accounting/acc_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Financial Report</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/accounting/acc_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Bills Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/accounting/acc_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div className='app-name'>
            <h1>Purchasing & Operations</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Web Based RFQ</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>PO Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Credit Memos</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>RFQ Status</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Purchase History</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Components Managements</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_6.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Reports</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_7.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Bills & Invoice</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_8.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Vendors Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/purchase/pur_9.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='app-name'>
            <h1>Sales</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Quotation Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Orders Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Customer Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Products Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Sales Record</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Opportunity & Pipeline</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_6.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Sales Campaign</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_7.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Return Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_8.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Profit Calculation</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/sales/sls_9.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='app-name'>
            <h1>Inventory Management</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Product Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Price Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Goods Receipts</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Goods Issues</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Transactions</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Transfer</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_6.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Inventory Report</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_7.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Serial Number Mngt</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_8.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Inventory Counting</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/inventory/inv_9.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='app-name'>
            <h1>Project & Resource Management</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>Project Stage</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_1.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Task Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_2.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Team Management</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_3.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Gantt Chart</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_4.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Modules/Sprints </h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_5.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Resource Planning</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_6.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Time Sheet</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_7.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-card">
              <div className="product-card-title">
                <h1>Project Meetings</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/mangement/mgt_8.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='app-name'>
            <h1>POS</h1>
            <hr />
          </div>

          <div className="product-static">
            <div className="product-card">
              <div className="product-card-title">
                <h1>POS</h1>
                {/* <img className="product-star-img" src="images/nav.png" /> */}
              </div>
              {/* <div className="product-card-p"><p>Customise yourself<br/>and your brand!</p></div> */}
              <div className="product-app-functions">
                <div className="product-img">
                  <img className="product-app-img" src="images/pos/pos.png" />
                </div>
                <div className="product-button">
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <img className="product-star-img" src="images/star.png" />
                  <div className="product-app-button-one">
                    <button className="button-one">Save for later</button>
                  </div>
                  <div className="product-app-button-two">
                    <button className="button-app" >Download Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      </>
  )
}
