// Normal Imports

import { Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//Starting page imports

import Home from './pages/home/Home';
import Trail from './pages/home/Trail';
import Contact from './pages/home/Contact';
import About from './pages/home/About';
import Products from './pages/home/Products';
import Team from './pages/home/Team';

// Authentication Part Imports

import AdminHome from "./pages/allAuthentications/components/HomeAdmin";
import Login from "./pages/allAuthentications/components/Login";
import Signup from "./pages/allAuthentications/components/Signup";
import ProtectedRoute from "./pages/allAuthentications/components/ProtectedRoute";
import { UserAuthContextProvider } from "./pages/allAuthentications/context/UserAuthContext";

// Admin module Imports

import AdminDashboard from './pages/admin/adminDashboard';
import AdminDailyUpdates from './pages/admin/adminDailyUpdates';
import AdminEmployeeCheckin from './pages/admin/adminEmployeeCheckin';
import AdminLeaveRequest from './pages/admin/adminLeaveRequest';
import AdminLocationHistory from './pages/admin/adminLocationHistory';
import AdminMyAccount from './pages/admin/adminMyAccounts';
import AdminMyBranches from './pages/admin/adminMyBranches';
import AdminMyDepartments from './pages/admin/adminMyDepartments';
import AdminMyEmployees from './pages/admin/adminMyEmployees';
import AdminMyPlans from './pages/admin/adminMyPlans';
import AdminReports from './pages/admin/adminReports';
import AdminShiftManagement from './pages/admin/adminShiftManagement';

// HRMS Module Imports

import CreateEmployee from './pages/hrms/createEmployees'; 
import CreateShiftSlot from './pages/hrms/createShiftSlot'; 
import EmployeeCheckinDetails from './pages/hrms/empCheckinDetails';
import CertificateManagement from './pages/hrms/certificateManagement';
import ManageLeaveRequest from './pages/hrms/manageLeaveRequest';
import ProjectMeetings from './pages/hrms/projectMeeting';
import HrmsReports from './pages/hrms/hrmsReports';




function App() {
  return (
    <>
      <Routes>
        {/* Normal Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/product" element={<Products />} />
        <Route exact path="/trial-form" element={<Trail />} />
        <Route exact path="/our-team" element={<Team />} />
        <Route exact path="/contact-us" element={<Contact />} />
        {/* <Route exact path="/pricing" element={<Pricing />} /> */}
        {/* <Route exact path="/purchase-form/:v_id/:rfq_id" element={<Purchase />} /> */}
      </Routes>

      <UserAuthContextProvider>
        <Routes>
          {/* Admin Routes */}
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <AdminHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminDashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminDailyUpdates"
            element={
              <ProtectedRoute>
                <AdminDailyUpdates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminEmployeeCheckin"
            element={
              <ProtectedRoute>
                <AdminEmployeeCheckin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminLeaveRequest"
            element={
              <ProtectedRoute>
                <AdminLeaveRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminLocationHistory"
            element={
              <ProtectedRoute>
                <AdminLocationHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminMyAccount"
            element={
              <ProtectedRoute>
                <AdminMyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminMyBranches"
            element={
              <ProtectedRoute>
                <AdminMyBranches />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminMyDepartments"
            element={
              <ProtectedRoute>
                <AdminMyDepartments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminMyEmployees"
            element={
              <ProtectedRoute>
                <AdminMyEmployees />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminMyPlans"
            element={
              <ProtectedRoute>
                <AdminMyPlans />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminReports"
            element={
              <ProtectedRoute>
                <AdminReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminShiftManagement"
            element={
              <ProtectedRoute>
                <AdminShiftManagement />
              </ProtectedRoute>
            }
          />

          {/* HRMS Routes */}

          <Route
            path="/createEmployees"
            element={
              <ProtectedRoute>
                <CreateEmployee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createShiftSlot"
            element={
              <ProtectedRoute>
                <CreateShiftSlot />
              </ProtectedRoute>
            }
          />
          <Route
            path="/empCheckinDetails"
            element={
              <ProtectedRoute>
                <EmployeeCheckinDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/certificateManagement"
            element={
              <ProtectedRoute>
                <CertificateManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manageLeaveRequest"
            element={
              <ProtectedRoute>
                <ManageLeaveRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/projectMeeting"
            element={
              <ProtectedRoute>
                <ProjectMeetings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hrmsReports"
            element={
              <ProtectedRoute>
                <HrmsReports />
              </ProtectedRoute>
            }
          />




          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </UserAuthContextProvider>
    </>
  );
}

export default App;

