import React from 'react'
import './adminApp.css';
import Sidebar from './components/Sidebar';
// import InsideNav from '../insideNavigationBar';

export default function adminDailyUpdates() {
    return (
        <>
        {/* <InsideNav /> */}
            <div className="txt-container">
                <Sidebar />
                <div>
                    <h1>Admin Daily Update page</h1>
                </div>
            </div>
        </>
    )
}
