import React, { Component } from 'react'
import Slider from "react-slick";
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default class Team extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: true,
      arrows:true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      // prevArrow:'<button type="button" data-role="none" class="slick-prev">Previous</button>',
      // nextArrow:'<button type="button" data-role="none" class="slick-next">Next</button>',
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1008,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
          // settings: 'unslick',
        },
        {
          breakpoint: 608,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
      <Nav />
        <section className='meet-our-team'>
        <div className='team-title'>
          <h1>Operations Head</h1>
        </div>
        <div class="row">
          <div class="col-md-5">
            <img class="image-flex" src="images/opradmin.png" />
          </div>
          <div class="col-md-7">
            <div class="subtitle-1">
            <div className='team-role'>Shujaat Nazir</div>
              <p>In charge of inspired leadership for the operation of the organization’s lines of business, making important policy and strategic decision, as well as the development and implementation of operation policies and procedures. To oversee budgeting, reporting, planning and auditing. Understanding of necessary legal and regulatory documents so to address problems and opportunities for the company.
              </p>
            </div>
          </div>
        </div>

        <div className='team-title'>
          <h1>Head Of Sales</h1>
        </div>
        <div class="row">
          <div class="col-md-5">
            <img class="image-flex" src="images/sales.png" />
          </div>
          <div class="col-md-7">
            <div class="subtitle-1">
            <div className='team-role'>Kashif Nazir</div>
              <p>Assess the strengths and weakness of the sales team and manage the sales program accordingly. Providing on ground support for sales associates as they generate leads and close new deals. Meet with clients to discuss their evolving needs and to assess the quality of the company’s relationship with them. Develop and implement new sales initiatives, strategies and programs to capture key demographics.
              </p>
            </div>
          </div>
        </div>

        <div className='team-title'>
          <h1>Marketing</h1>
        </div>
        <div class="row">
          <div class="col-md-5">
            <img class="image-flex" src="images/marketing.png" />
          </div>
          <div class="col-md-7">
            <div class="subtitle-1">
            <div className='team-role'>Meghna Yadav</div>
              <p>Organize promotional events at trade shows and other venues. Identify weaknesses in existing marketing campaigns and develop pragmatic solutions within budgetary constraints. Communicate with senior management about marketing initiatives and project metrics, as well as to brainstorm fresh strategies
              </p>
            </div>
          </div>
        </div>

        <div className='team-title'>
          <h1>Technical Team</h1>
        </div>
        <div className=''>
          <Slider {...settings}>
            <div className='testinomial-section'>
              <div className='row'>
                <div className='col-lg-3'>
                  <img className="image-flex" src='./images/nswhite.png'></img>
                </div>
                <div className='col-lg-9'>
                  <div className='team-role'>Senior Developer - Nishad Saiyyad</div>
                  <div className='about-team'>
                    <p>
                      A senior developer thinks strategically and has a deep understanding of all stages of digital development for apps.
                      She also manage a team of developers and will be expected to encourage creativity and efficiency throughout complex digital projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='testinomial-section'>
            <div className='row'>
              <div className='col-lg-3'>
                <img className="image-flex" src='./images/swhite.png'></img>
              </div>
              <div className='col-lg-9'>
                <div className='team-role'>Junior Developer - Sachin Pal</div>
                <div className='about-team'>
                  <p>
                  A Junior developer is someone who designs and develops applications, write’s clean code, takes part in the development lifecycle, fix bugs, investigates technologies & follows innovative trends.
                  
                  </p>
                </div>
              </div>
            </div>
            </div>


            {/* <div className='testinomial-section'>
            <div className='row'>
              <div className='col-lg-3'>
                <img className="image-flex" src='./images/fewhite.png'></img>
              </div>
              <div className='col-lg-9'>
                <div className='team-role'>Developer - Feshan Khan</div>
                <div className='about-team'>
                  <p>
                  The developer will make sure the designs comply to specifications and prepare and release software components. They will also support continuous improvement through exploring alternative technologies and presenting them for architectural review.
                  </p>
                </div>
              </div>
            </div>
            </div> */}

            

            

            <div className='testinomial-section'>
            <div className='row'>
              <div className='col-lg-3'>
                <img className="image-flex" src='./images/twhite.png'></img>
              </div>
              <div className='col-lg-9'>
                <div className='team-role'>Junior Developer - Tanishq Bhoir</div>
                <div className='about-team'>
                  <p>
                  A Junior Developer is responsible for creating responsive & dynamic frontend screens, connecting application with firebase to read and write data, and lastly collaborating with team members for new features.
                  </p>
                </div>
              </div>
            </div>
            </div>

            <div className='testinomial-section'>
            <div className='row'>
              <div className='col-lg-3'>
                <img className="image-flex" src='./images/rwhite.png'></img>
              </div>
              <div className='col-lg-9'>
                <div className='team-role'>Developer - Rahul Padhy</div>
                <div className='about-team'>
                  <p>
                  A Developer is responsible for writing well designed, testable, efficient code website by using best software development practices. To create website layout/user interface by using standard practices.
                  </p>
                </div>
              </div>
            </div>
            </div>

      </Slider>
        </div >
      </section >
      <Footer />
      </>
      
    )
  }
}
