import React from 'react'
import './adminApp.css';
import Sidebar from './components/Sidebar';

export default function adminMyBranches() {
    return (
        <>
            <div className="txt-container">
                <Sidebar />
                <div>
                    <h1>Admin My Branches page</h1>
                </div>
            </div>
        </>
    )
}
