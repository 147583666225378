import React, { useState, useRef, useEffect } from 'react';

import { useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Offcanvas, NavDropdown, Container, Form, FormControl, Button } from 'react-bootstrap';

function NavigationBars() {
  // const [isNavExpanded, setIsNavExpanded] = useState(false);
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-33">
          <Container fluid>
            <Navbar.Brand href="/"><img className="logoimages" src='https://revooerp.com/images/revoologo.png' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} href="/">
                  <img className="logoimages" src='https://revooerp.com/images/revoologo.png' />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link className={splitLocation[1] === "" ? "active nav-link" : "nav-link rkp-nav-link"} href="/">Home</Nav.Link>
                  <Nav.Link className={splitLocation[1] === "about" ? "active nav-link" : "nav-link rkp-nav-link"} href="/about">About</Nav.Link>
                  <Nav.Link className={splitLocation[1] === "product" ? "active nav-link" : "nav-link rkp-nav-link"} href="/product">Products</Nav.Link>
                  {/* <Nav.Link className={splitLocation[1] === "pricing" ? "active nav-link" : "nav-link rkp-nav-link"} href="/pricing">Pricing</Nav.Link> */}
                  <Nav.Link className={splitLocation[1] === "our-team" ? "active nav-link" : "nav-link rkp-nav-link"} href="/our-team">Crew</Nav.Link>
                  <Nav.Link className={splitLocation[1] === "contact-us" ? "active nav-link" : "nav-link rkp-nav-link"} href="/contact-us">Contact</Nav.Link>
                  <Nav.Link className= "nav-link"  href="#">Sign in</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      <a href="https://wa.me/+919967706767"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
  )
}

export default NavigationBars;