import React, { useState } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
}from "react-icons/fa";
import { NavLink } from 'react-router-dom';


const Sidebar = ({children}) => {
    const[isOpen ,setIsOpen] = useState(true);
    const toggle = () => setIsOpen (!isOpen);
    const menuItem=[
        {
            path:"/adminDashboard",
            name:"Dashboard"
            // icon:<FaTh/>
        },
        {
            path:"/adminDailyUpdates",
            name:"Daily Updates"
            // icon:<FaUserAlt/>
        },
        {
            path:"/adminEmployeeCheckin",
            name:"Employee Check-in"
            // icon:<FaRegChartBar/>
        },
        {
            path:"/adminLeaveRequest",
            name:"Leave Request"
            // icon:<FaCommentAlt/>
        },
        {
            path:"/adminLocationHistory",
            name:"Location History"
            // icon:<FaShoppingBag/>
        },
        {
            path:"/adminMyAccount",
            name:"My Account"
            // icon:<FaThList/>
        },
        {
            path:"/adminMyBranches",
            name:"My Branches"
            // icon:<FaThList/>
        },
        {
            path:"/adminMyDepartments",
            name:"My Departments"
            // icon:<FaThList/>
        },
        {
            path:"/adminMyEmployees",
            name:"My Employees"
            // icon:<FaThList/>
        },
        {
            path:"/adminMyPlans",
            name:"My Plans"
            // icon:<FaThList/>
        },
        {
            path:"/adminReports",
            name:"My Reports"
            // icon:<FaThList/>
        },
        {
            path:"/adminShiftManagement",
            name:"Shift Management"
            // icon:<FaThList/>
        }
    ]
    return (
        <>
        {/* // <div className="container"> */}
           <div style={{width: isOpen ? "200px" : "50px"}} className="sidebar">
               <div className="top_section">
                   <a href='/home'><h1 style={{display: isOpen ? "block" : "none"}} className="logo">Logo</h1></a>
                   {/* <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div> */}
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           {/* <div className="icon">{item.icon}</div> */}
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
           </div>
           {/* <main>{children}</main> */}
        {/* // </div> */}
        </>
        
    );
};

export default Sidebar;