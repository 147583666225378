import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
        <section className='footer-bg'>
            <div className="container-fluid footer-pos">
                <a className="footer-brand" href="/"><img className="footer-img" src='https://revooerp.com/images/revoologo.png'/></a>
                <div className="signin-footer">
                    <ul className='mb-lg-0'>
                        <li>
                            <a href="https://login.revooerp.com/" className="nav-link">Sign in</a>
                        </li>
                        <a className="btn btn-primary mb5" href="/trial-form">Try it free</a>
                    </ul>
                </div>
            </div>
        </section>
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-50">
                    <div className="footer-widget">
                        <div className="footer-header">
                        <Link to="/"><h1>Revoo ERP</h1></Link>
                        <hr/>
                        </div>
                        <div className="footer-text">
                        <p>Revoo, your personal guide for entrepreneurial success. Manage, Access, Maintain and Grow your business with Revoo, The one-stop-shop solution for every business need.</p>
                        </div>
                        <div className="footer-social-icon">
                        <span>Follow us</span>
                        <a href="https://www.facebook.com/Revoo-103658515686016"><i className="fa fa-facebook-f facebook-bg" /></a>
                        {/* <a href="#"><i className="fa fa-twitter twitter-bg" /></a> */}
                        <a href="https://www.instagram.com/revooerp/"><i className="fa fa-instagram instagram-bg" /></a>
                        {/* <a href="#"><i className="fa fa-google-plus google-bg" /></a> */}
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
                    <div className="footer-widget">
                        <div className="footer-widget-heading">
                        <h3>Useful Links</h3>
                        </div>
                        <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/product">Products</a></li>
                        {/* <li><a href="#">portfolio</a></li> */}
                        {/* <li><a href="#">Contact</a></li> */}
                        {/* <li><a href="/about">About us</a></li> */}
                        {/* <li><a href="#">Our Services</a></li> */}
                        <li><a href="/our-team">Our Crew</a></li>
                        <li><a href="/contact-us">Contact us</a></li>
                        <li><a href="/trial-form">Trial</a></li>
                        </ul>
                    </div>
                    </div>
                    
                </div>
                </div>
                <hr/>
            </div>
            <div className="copyright-area">
                <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                    <div className="copyright-text">
                        <p>Copyright © 2022, All Right Reserved <a target="_blank" href="https://revooerp.com/">Revoo ERP</a></p>
                        <p>Designed and Developed By <a target="_blank" href="https://an-intl.co/">AN International</a></p>
                    </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div className="footer-menu">
                        <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="#">Terms</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Policy</a></li>
                        <li><a href="/contact-us">Contact</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer