import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBr-TjYkCaHCSvuRN0U6UajSeJTNrzYv64",
  authDomain: "revoo-57e23.firebaseapp.com",
  projectId: "revoo-57e23",
  storageBucket: "revoo-57e23.appspot.com",
  messagingSenderId: "702579453139",
  appId: "1:702579453139:web:082dbb521cac125eac3826"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
