import React from 'react';
import AboutMidImage from './AboutComponent/AboutMidImage';
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default function About() {
  return (
    <>
    <Nav />
      <section className='about-head'>
        <div className='about-title'>
          <h1>Quotation about company's vision/ Mission Statement.</h1>
          <AboutMidImage />
          {/* <div className='about-head-img'></div> */}
          {/* <img src='images/imageswindow.png'/> */}
        </div>
      </section>
      <section className='triangle-section'>
        <div className='triangle-div'>
          <div className='triangle-one'>
            <a>We at Revoo work to make your business efficient and convenient. Revoo is a multiverse of app which gives you and your team all the useful tools for your business at one place. </a>
          </div>
          <div className='triangle-about'>
            <img src='images/triangle.png' className='about-triangle' alt='Triangle'></img>
            {/* <div className='triangle-inside'>
            <div className='employees'>
              <div className='amount'>
                20000+
              </div>
              <div className='name'>  
                Employees
              </div>
            </div>
            <div className='users'>
              <div className='amount'>
                3 Million
              </div>
              <div className='name'>
                Users
              </div> 
            </div>
            <div className='partners'>
              <div className='amount'>
                70000+  
              </div>
              <div className='name'>
                Partners  
              </div>
            </div>
          </div> */}
          </div>
          <div className='triangle-two'>
            <a>Revoo is probably the most smartest, versatile and efficient app for your business. Revoo is not only your best business buddy but also your smartest digital manager. </a>
          </div>
        </div>
        {/* <div className='row'>
        <div className='col-sm-6 order-md-2'>
          <div><a>Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </a></div>
        </div>
        <div className='col-xl-12'>
          <div className='triangle-about'>
            <img src='images/about-triangle.png' className='about-triangle'></img>
          </div>
        </div>
      </div> */}
      </section>
      {/* <section className='meet-our-team'>
        <div className='team-title'>
          <h1>Meet our Team</h1>
        </div>
        <div className=''>
          <Team />
        </div>
      </section> */}
      <section className='about-offer'>
        <div className='offer-header'>
          <h1>
            What can we offer?
          </h1>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <img className='about-offer-img' src='images/about-offer.png'></img>
            </div>
            <div className='col-lg-6'>
              <div className='container-fluid'>
                <div className='about-offer-header'>
                  <h1>Role of Revoo</h1>
                  <p>The better question should be what we do not offer? There is not a single thing left untouched by our Revoo ERP Software. We fulfil all your entrepreneurial needs. You just need one app, one touchpoint, one point of contact, and you get what you want, right from finance checks to human resources, from accounts to project management, from sales reports to employees' whereabouts. Everything is offered from us, that too just at a single place- Revoo. Revoo is all you want for your business success. Revoo Services covers all the major concerns any business will have, and we have solved most of the managerial issues but created the world's most dynamic ERP software ready to serve you- Revoo.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
