import React, { Component } from 'react'
import Slider from "react-slick";

export default class Testimonials extends Component {
    render() {
        var settings = {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          centerMode:true,
        //   arrows:true,
          autoplaySpeed: 2000,
          pauseOnHover: true,
          // prevArrow:'<button type="button" data-role="none" class="slick-prev">Previous</button>',
          // nextArrow:'<button type="button" data-role="none" class="slick-next">Next</button>',
          responsive: [
            {
              breakpoint:1500,
              settings:{
                slidesToShow:1,
                slidesToScroll:1,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1008,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode:true,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode:true,
              },
              // settings: 'unslick',
            },
            {
              breakpoint: 608,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };
        return (
          <div>
            {/* <Slider {...settings}> */}
              <div className='testinomial-section'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <img className="image-flex" src='images/admin.png'></img>
                    </div>
                    <div className='col-lg-9'>
                        <div className='testinomial-review'>
                            <h1>Asif Nazir</h1>
                            <p>We at REVOO create beautiful software that solves business problems. REVOO offers more than a single product. It also includes a complete suite of products. Our commitment to continual refinement and improving your experience is what you get. REVOO will spend your money wisely. We spend more on product development and customer service than we do in marketing and sales.</p>
                        </div>
                    </div>
                </div>
              </div>
              {/* <div className='testinomial-section'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <img className="image-flex" src='images/user.png'></img>
                    </div>
                    <div className='col-lg-9'>
                        <div className='testinomial-review'>
                            <h1>From the speaker..</h1>
                            <p>....</p>
                        </div>
                    </div>
                </div>
              </div>
              <div className='testinomial-section'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <img className="image-flex" src='images/user.png'></img>
                    </div>
                    <div className='col-lg-9'>
                        <div className='testinomial-review'>
                            <h1>From the speaker..</h1>
                            <p>....</p>
                        </div>
                    </div>
                </div>
              </div> */}
            {/* </Slider> */}
          </div>
        );
      }
}
