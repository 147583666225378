import React from 'react'
import Footer from '../home/Footer';
import Nav from '../home/NavigationBars';

export default function Trail() {
  return (
    <>
    <Nav />
        <section className='trail'>
        <div className='form-head'>
            <h1>Get Instant Access</h1>
            <p>Continue your free trial for 14-days without any credit card and experience the best.</p>
        </div>
        <center><hr className='trail-hr' width="300px;" size="10" align="center"/></center>
        <div className='trail-form'>
            <div className='container'>
                <div className='row rkp-test' >
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='First Name'></input>
                    </div>
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='Last Name (Optional)'></input>
                    </div>
                    <div className='col-lg-12'>
                        <input type={'text'}  className='rkp-control' placeholder='E-mail'></input>
                    </div>
                    <br/>
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='Password'></input>
                    </div>
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='Phone Number'></input>
                    </div>
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='Company Name (Optional)'></input>
                        
                    </div>
                    <div className='col-lg-6'>
                        <input type={'text'}  className='rkp-control' placeholder='Number Of Employees (Optional)'></input>
                    </div>
                    <div className='col-lg-6'>
                        <select type={'text'}  className='rkp-control'>
                            <option>Country</option>
                        </select>
                    </div>
                    <div className='col-lg-6'>
                        <select type={'text'} className='rkp-control'>
                            <option>Language</option>
                        </select>
                    </div>
                </div>
                <div className='start-button'>
                    <button className='btn'>Start Trail<span>&nbsp;&nbsp;&nbsp;<i className='fa fa-angle-right'></i></span></button>
                </div>
                <div className='accept-tm'>By clicking continue, you agree to the <span>Terms & Conditions</span> and <span>Privacy Policy</span> of Revoo.</div>
            </div>
        </div>
    </section>
    <Footer />
    </>
    
  )
}
