import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Footer from '../../home/Footer';
import Nav from '../../home/NavigationBars';

const Home = () => {

  const headerStyle = {
    padding: "24px",
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: "1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap"
  };
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
    <Nav />
      <div className="txt-module">
        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Admin</h5>
                        <p className="card-text">Admin Module Details</p>
                        <a href="/adminDashboard" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">HRMS</h5>
                        <p className="card-text">HRMS Module Details</p>
                        <a href="/createEmployees" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Sales</h5>
                        <p className="card-text">Sales Module Details</p>
                        <a href="#" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Inventory</h5>
                        <p className="card-text">Inventory Module Details</p>
                        <a href="#" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Project and Resource</h5>
                        <p className="card-text">Project and Resource Module Details</p>
                        <a href="#" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Purchasing and Operation</h5>
                        <p className="card-text">Purchasing and Operation Module Details</p>
                        <a href="#" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="txt-card">
            <div className="row no-gutters">
                <div className="col-sm-5">
                    <img className="card-img" src="images/1.png" alt=" Card" />
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="txt-card-title">Accounting and Finance</h5>
                        <p className="card-text">Accounting and Finance Module Details</p>
                        <a href="#" className="btn btn-primary">Details Here</a>
                    </div>
                </div>
            </div>
        </div>
      </div>

      {/* <div className="txt-body">
      <div className="p-4 txt-box mt-3 text-center">
        Hello Welcome <br />
        {user && user.email}
      </div> */}
      <div className="d-grid gap-2">
        <Button variant="primary" onClick={handleLogout}>
          Log out
        </Button>
      </div>
      {/* </div> */}
    <Footer />
    </>
  );
};

export default Home;
